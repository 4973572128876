@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900);

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat";
}

body {
  top: 0 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

input[type="file"] {
  display: none;
}

/* Image Classes */

.image_0 {
  background-image: url(/public/images/1.png);
}

.image_1 {
  background-image: url(/public/images/2.png);
}
.image_2 {
  background-image: url(/public/images/3.png);
}
.image_3 {
  background-image: url(/public/images/4.png);
}
.image_4 {
  background-image: url(/public/images/5.png);
}

.skiptranslate {
  display: none;
}
