.butt:hover{
    background-color: skyblue !important;
}
.butt{
    background-color: blue !important;

}
.buttondiv{
    display: flex;
    justify-content: center;
}